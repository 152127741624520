<!--  -->
<template>
  <div class='teacher'>
    <div class="content">
      <div class="nav">
        <div>ABOUT</div>
        <div>专家团队</div>
      </div>
      <div class="info">
        <div>
          <img :src="info.img" alt="">
          <div>
            <h1>{{info.name}}</h1>
            <div>{{info.title}}</div>
          </div>
        </div>
        <p>{{info.content}}</p>
      </div>
    </div>
    <div class="list">
      <div v-for="(item, index) in list" :key="index" @click="handleItem(index)">
        <img :src="item.img" alt="">
        <div v-if="idx == index">{{item.name}}</div>
        <div class="name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/home.js'

export default {
  components: {},
  data() {
    return {
      list: [],
      info: {},
      idx: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTeacherArr()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getTeacherArr () {
      Api.getTeacherArr().then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
          let id = this.$route.query.id || this.list[0].id
          this.list.forEach((item, index) => {
            if (item.id == id) {
              this.idx = index
              this.info = item
            }
          })
        } else {
          this.$message.error("名师信息获取失败")
        }
      })
    },
    handleItem (i) {
      this.idx = i
      this.info = this.list[i]
    }
  },
}
</script>

<style lang='less' scoped>
.teacher {
  background-color: #fff;
  padding: 30px;
  padding-right: 60px;
  .content {
    display: flex;
    margin-top: 20px;
    .nav {
      width: 200px;
      height: 200px;
      background-color: #06B487;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      > div {
        color: #e3e3e3;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    .info {
      flex: 1;
      margin-left: 30px;
      > div {
        display: flex;
        > img {
          width: 300px;
          height: 300px;
        }
        > div {
          margin-left: 20px;
          > div {
            margin-top: 40px;
          }
        }
      }
      p {
        margin: 30px 0;
        word-break: break-all;
        white-space: pre-wrap;
        text-indent: 30px;
      }
    }
  }
  .list {
    width: 100%;
    overflow-y: auto;
    display: flex;
    padding-bottom: 20px;
    > div {
      margin-right: 50px;
      position: relative;
      cursor: pointer;
      img {
        width: 200px;
        height: 210px;
      }
      > div {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        line-height: 210px;
        color: #fff;
        background-color: rgba(6, 180, 135, 0.6);
      }
      .name {
        display: none;
      }
      &:hover {
        .name {
          display: block;
        }
      }
    }
    > div:last-child {
      margin-right: 0;
    }
  }
}
</style>