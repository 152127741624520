import request from '../utils/request'

export default {
  getNewsArr: (data) => {
    return request({
      url: 'Home/Apinew/home_zixun',
      method: 'post',
      data: data
    })
  },
  getNeedCode: (data) => {
    return request({
      url: 'Home/ApiRenew/is_need_yzm',
      method: 'post',
      data: data
    })
  },
  login: (data) => {
    return request({
      url: 'Home/ApiRenew/get_token_new',
      method: 'post',
      data: data
    })
  },
  defLogin: (data) => {
    return request({
      url: 'Home/Apinew/get_token_gzhref',
      method: 'post',
      data: data
    })
  },
  loginOut: (data) => {
    return request({
      url: 'Home/Apinew/login_out',
      method: 'post',
      data: data
    })
  },
  getTeacherArr: (data) => {
    return request({
      url: 'Home/Apinew/home_msfc',
      method: 'post',
      data: data
    })
  },
  getTixi: (data) => {
    return request({
      url: 'Home/Apinew/home_kctx',
      method: 'post',
      data: data
    })
  },
  getBanner: (data) => {
    return request({
      url: 'Home/Apinew/home_lbt',
      method: 'post',
      data: data
    })
  },
  getLinks: (data) => {
    return request({
      url: 'Home/Apinew/home_link',
      method: 'post',
      data: data
    })
  },
  otherLogin: (data) => {
    return request({
      url: 'Home/ApiRenew/get_gz_send_user',
      method: 'post',
      data: data
    })
  },
  getXuefenList: (data) => {
    return request({
      url: 'Home/ApiRenew/card_xuefen',
      method: 'post',
      data: data
    })
  },
}
